import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { LoginProvider } from '../../providers/login';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import DocumentInput from '../../components/DocumentInput';
import axios from 'axios';
import { Contract } from '../../models/contract';

import Alert from '../../components/Alert';

import './ContractPage.scss';

function ContractPage() {
    const navigate = useNavigate()
    const { id } = useParams()
    const [documents, setDocuments] = useState<any | null>(null)
    const [files, setFiles] = useState<{ id: number, file: File | null, error: string | null }[]>()
    const loginProvider = new LoginProvider()
    const [contract, setContract] = useState<Contract>()
    const [__, makeContractRequest] = useState<boolean>(false)
    const [alert, setAlert] = useState<CustomAlertType | null>(null);
    const reload = () => { makeContractRequest(!__) }

    useEffect(() => {
        if (!loginProvider.checkTokenValidity()) {
            navigate('/')
        } else if (id === undefined) {
            navigate('/dashboard')
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/client/auth/contract/${id}`, { headers: { Authorization: loginProvider.getToken() } })
                .then(r => {
                    r.data && setContract(r.data)
                    if (!r.data) navigate('/dashboard')
                    let docs = r.data.GeneratedContractDocuments?.filter((i: any) => i.Document)
                    if (docs) {
                        setDocuments(docs)
                        setFiles(docs.map((d: any) => { return { id: d.id, file: null, error: null } }))
                    }
                }, () => { })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [__])

    return (
        <>
        {alert && <Alert alert={alert}/>}
        <Box className="ContractPage" sx={{ height: { xs: "calc(100vh - 64px)", lg: "calc(100vh - 68.5px)" }, overflowY: "auto" }}>
            {!documents
            ? <Typography sx={{m:'auto', pt:20, display:'block', textAlign:'center'}}>Chargement en cours...</Typography>
            : <>
                <Typography sx={{ pt: 4, pb: 2, textAlign: 'center', fontSize: 20 }}>Contract #{contract?.ref}</Typography>
                <Box display={"flex"} justifyContent={"center"}>
                    <Button children="Voir mon contrat" variant="contained" onClick={async(e) => {
                        e.stopPropagation()
                        const content = await loginProvider.downloadContract(contract?.id!)
                        const url = window.URL.createObjectURL(content);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `Contract prestation formation ${contract?.ref}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode!.removeChild(link);
                    }} />
                </Box>
                <Grid container mt={0} className="ContractPageGrid">
                    {documents.map((d: any, index: number) => {
                        const file = { ...files?.find((f) => f.id === d.DocumentId) }
                        return <DocumentInput
                                    key={d.DocumentId}
                                    contract={contract}
                                    file={file}
                                    setFiles={setFiles}
                                    setAlert={setAlert}
                                    reload={reload}
                                    document={d}
                                    sx={{ pt: { xs: '3vh', sm: '5vh', md: '9vh' } }}
                                />
                    })}
                </Grid>
            </>}
        </Box>
        </>
    );
}

export default ContractPage;
