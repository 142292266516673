import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Avatar, Button, TextField, Box, Typography, Container } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { LoginProvider } from '../../providers/login'


export function LoginPage(props: any) {
    const navigate = useNavigate()
    const setIsLogin = props.setIsLogin;
    const loginProvider = new LoginProvider();
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    const [step, setStep] = useState(0)
    const [form, setForm] = useState<{ email: string, code: string, error: string | null }>({
        email: '',
        code: '',
        error: null,
    })

    useEffect(() => {
        if (loginProvider.checkTokenValidity()) {
            navigate('/dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container component="main" sx={{ maxWidth: "100%", height: { xs: "calc(100vh - 64px)", lg: "calc(100vh - 68.5px)" }, overflowY: "auto" }}>
            <Box
                sx={{
                    marginTop: 12,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    {step === 0 && <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        type="email"
                        InputProps={{ style: { borderRadius: 4 } }}
                        sx={{ borderRadius: 4 }}
                        autoFocus
                        onChange={e => {
                            let tmp = { ...form }
                            tmp.email = e.target.value
                            tmp.error = null
                            setForm(tmp)
                        }}
                    />}

                    {step === 1 && <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="code"
                        label="Code"
                        //inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        name="code"
                        type="text"
                        InputProps={{ style: { borderRadius: 4 } }}
                        sx={{ borderRadius: 4 }}
                        autoFocus
                        onChange={e => {
                            let tmp = { ...form }
                            tmp.code = e.target.value
                            tmp.error = null
                            setForm(tmp)
                        }}
                    />}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, backgroundColor: '#2d65b0' }}
                        onClick={async e => {
                            e.preventDefault()
                            if (step === 0) {
                                const res = await loginProvider.getCode(form.email)
                                if (res == null) {
                                    setStep(1)
                                }
                            } else if (step === 1) {
                                const res = await loginProvider.sendCode(form.email, form.code)
                                if (res == null) {
                                    setIsLogin(true)
                                    navigate('/dashboard')
                                } else {
                                    let tmp = { ...form }
                                    tmp.error = res
                                    setForm(tmp)
                                }
                            }
                        }}
                    >
                        {step === 0 ? "Send code" : "Sign in"}
                    </Button>
                    {form.error !== '' && form.error != null &&
                        <Box sx={{
                            backgroundColor: '#fdd',
                            padding: 2,
                            borderRadius: 4,
                            textAlign: 'center',
                        }}>
                            <Typography>
                                {form.error}
                            </Typography>
                        </Box>
                    }
                </Box>
            </Box>
        </Container>
    );
}

export default LoginPage;
