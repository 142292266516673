import React from 'react';
import { useState, useEffect, MouseEvent } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AppBar as NativeAppBar, Container, Toolbar, Typography, Box, IconButton, Menu, MenuItem, Avatar, Tooltip, Button } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { LoginProvider } from '../providers/login'
import logo from '../assets/logo.svg';

const pages = ['Dashboard'];

function AppBar(props: { setIsLogin: any; }) {
    const { setIsLogin } = props;
    const loginProvider = new LoginProvider();
    const navigate = useNavigate()

    const logOut = () => {
        loginProvider.logOut()
        setIsLogin(false)
        navigate('/login')
    }

    useEffect(() => {
        if (!loginProvider.token) return
        const state = loginProvider.checkTokenValidity()
        if (!state) logOut()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <NativeAppBar position="static" sx={{ backgroundColor: '#2d65b0' }}>
            <Container maxWidth="xl" >
                <Toolbar disableGutters>
                    <Box
                        component="img"
                        sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}
                        alt="Logo"
                        height={40}
                        src={logo}
                    />
                    <Typography
                        variant="h6"
                        noWrap
                        component={Link}
                        to="/dashboard"
                        sx={{
                            ml: 2,
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        RegVisor
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        {loginProvider.token && (<>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={(e) => {
                                        e.preventDefault()
                                        navigate('/' + page.toLowerCase())
                                        handleCloseNavMenu()
                                    }}>
                                        <Typography textAlign="center">{page}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu></>
                        )}
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component={Link}
                        to="/"
                        sx={{
                            mr: 2,
                            ml: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        RegVisor
                    </Typography>

                    {loginProvider.token && (<>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handleCloseNavMenu()
                                        navigate('/' + page.toLowerCase())
                                    }}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>
                        <Box sx={{ flexGrow: 0 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar
                                        sx={{
                                            fontSize:"16px"
                                        }}
                                        children={`${(loginProvider.username[0][0] + loginProvider.username[1][0]).toUpperCase()}`}
                                        />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem onClick={(e) => {
                                    e.preventDefault()
                                    handleCloseNavMenu()
                                    logOut()
                                }}>
                                    <Typography textAlign="center">Logout</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </>)}
                </Toolbar>
            </Container>
        </NativeAppBar>
    );
}

export default AppBar;
