import React, { useState } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import BackupIcon from '@mui/icons-material/Backup';
import { LoginProvider } from '../providers/login';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { Contract } from '../models/contract';
import Alert from '../components/Alert';

const STATUS_DESCRIPTION:{[key:string]:string} = {
    "conflict": "Non conforme",
    "wait": "En attente de traitrement",
    "valid": "Validé"
};

function DocumentInput(props: any) {
    const doc: any = props.document;
    const contract: Contract = props.contract;
    const setFiles: Function = props.setFiles;
    const setAlert: Function = props.setAlert;
    const file: { id: number, file: File | null, error: string | null } = props.file;
    const loginProvider = new LoginProvider();
    const [state, setState] = useState<string>(!doc.state ? 'wait' : doc.state);

    const url: string | null = doc.url ? doc.url.substring(doc.url.lastIndexOf('/') + 1) : null;

    async function submit(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        const nfile = e.target.files ? e.target.files[0] : null;

        if (nfile) {
            const allowed_filetypes = doc.Document.filetypes
            const max_size = (doc.Document.maxSize || 1)

            if (nfile.size >= max_size * 1024 * 1024) {
                setAlert({
                    variant:"warning",
                    message:`La taille du fichier ne peut dépassé ${max_size} Mo.`
                });
                return;
            } else if (!((allowed_filetypes?.map((e: any) => e.label.toLowerCase()) ?? ['pdf']).includes(nfile.name.substring(nfile.name.lastIndexOf(".") + 1).toLowerCase()))) {
                setAlert({
                    variant:"warning",
                    message:`Only ${allowed_filetypes?.map((e: any) => e.label.toUpperCase()).join(', ')} supported`
                });
                return;
            }

            setFiles((files: any) => {
                return files!.map((f: any) => {
                    if (f.id === file.id) f.file = nfile;
                    return f
                })
            })
            try {
                await loginProvider.sendDocument({ id: doc.DocumentId, contractId: props.contract.id, file: nfile!, error: null })
                .then(r => {
                    if (!r) {
                        setAlert({
                            variant:"error",
                            message:"Échec de la communication avec les serveurs"
                        });
                    } else if (r.data) {
                        if (r.data.alert) {
                            setAlert(r.data.alert);
                        }
                    }
                    props.reload()
                });
                setState('wait')
            } catch (err) {
                setAlert({
                    variant:"error",
                    message:`Erreur: ${err}`
                });
            }
        }
    }

    return (
        <div>
            <div className={`status-color status-${state}`} title={STATUS_DESCRIPTION[state]}></div>
            <Typography noWrap sx={{ fontSize:"medium" }}>
                {doc.Document.label}
            </Typography>
            <div className='sendFileBlock'>
                {!file?.file && !url ? <>
                    <BackupIcon sx={{ color: '#9f9f9f', height: 40, width: 40 }} onClick={() => (document.querySelector(`#input-${doc.id}`) as HTMLElement)?.click()}/>
                    <Typography sx={{ fontSize:"small" }}>
                        Choisir un fichier
                        <Typography sx={{ fontSize:"x-small", color:"#666" }}>
                            Supporté(s) : {doc.Document.filetypes?.map((e: any) => e.label.toUpperCase()).join(', ') || 'PDF'}
                        </Typography>
                    </Typography>
                </>
                    : <>
                        {contract.state !== 'compliant' && state !== 'valid' &&
                            <EditIcon sx={{
                                color: '#9f9f9f',
                                height: 24,
                                width: 24
                                }}
                                onClick={() => (document.querySelector(`#input-${doc.id}`) as HTMLElement)?.click()}/>
                        }
                        <VisibilityIcon
                            sx={{ color: '#9f9f9f', height: 28, width: 28 }}
                            onClick={async e => {
                                e.stopPropagation()
                                const content = await loginProvider.downloadDocument(doc.GeneratedContractId, doc.url)
                                const url = window.URL.createObjectURL(content);
                                const link = document.createElement("a");
                                link.href = url;
                                link.setAttribute("download", doc.filename);
                                document.body.appendChild(link);
                                link.click();
                                link.parentNode!.removeChild(link);
                            }}
                        />
                        <Typography sx={{ fontSize:"small" }}>
                            {doc?.filename}
                        </Typography>
                    </>
                }
                {contract.state !== 'compliant' && state !== 'valid' &&
                    <input
                        id={`input-${doc.id}`}
                        type="file"
                        name="file"
                        style={{ display:"none" }}
                        accept={(doc.Document.filetypes as Array<{ label: string }>)?.map((e) => "." + e.label.toLowerCase()).join(',') ?? "application/pdf"}
                        onChange={(e) => submit(e)}
                    />
                }
            </div>
                { doc.NonComplianceReason && 
                    <Box mt={1}>
                        <Typography sx={{ fontSize:"x-small", color:"#666" }}>
                            Raison de non conformitée
                        </Typography>
                        <Typography sx={{ fontSize:"small" }}>{doc.NonComplianceReason.label}</Typography>
                    </Box>
                }
        </div>
    );
}

export default DocumentInput;
