import React from 'react';
import './DashboardPage.css';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoginProvider } from '../../providers/login';
import { Box, Container, List, Typography, Divider, Grid, Button, Dialog, Paper, TextField } from '@mui/material';
import ContractItem from '../../components/Contract';
import { Contract } from '../../models/contract';

function DashboardPage(props: any) {
    const loginProvider = new LoginProvider();
    const navigate = useNavigate()
    const [contracts, setContracts] = useState<Contract[] | null>(null);

    function setClasses(el: any) {
        // Dans certains cas, le client a el à null.
        // Ceci est un dirty fix.
        if (!el || !el.scrollHeight || !el.clientHeight || !el.classList) {
            return;
        }
    
        const isScrollable = el.scrollHeight > el.clientHeight;

        // GUARD: If element is not scrollable, remove all classes
        if (!isScrollable) {
            el.classList.remove('is-bottom-overflowing', 'is-top-overflowing');
            return;
        }

        // Otherwise, the element is overflowing!
        // Now we just need to find out which direction it is overflowing to (can be both)
        const isScrolledToBottom = el.scrollHeight <= el.clientHeight + el.scrollTop;
        const isScrolledToTop = isScrolledToBottom ? false : el.scrollTop === 0;
        el.classList.toggle('is-bottom-overflowing', !isScrolledToBottom);
        el.classList.toggle('is-top-overflowing', !isScrolledToTop);
    }

    useEffect(() => {
        if (!loginProvider.checkTokenValidity()) {
            navigate('/login')
            return
        }

        document.querySelector('#contracts')?.addEventListener('scroll', (e) => {
            const el = e.currentTarget;
            setClasses(el);
        });

        setClasses(document.querySelector('#contracts'));
        // eslint-disable-next-line react-hooks/exhaustive-deps

        loginProvider.getContracts().then(
            (e) => {
                setContracts(e ?? [])
            }
        )

    }, [])

    return (
        <Container id="DashboardPage" sx={{ maxWidth: "100%", height: { xs: "calc(100vh - 64px)", lg: "calc(100vh - 68.5px)" }, overflowY: "auto" }}>
            <Box
                sx={{
                    marginTop: 12,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left'
                }}
            >
                <Grid container sx={{ p: 1, justifyContent: 'space-between', alignItems: 'center', borderBottomWidth: 1, borderBottomColor: "#2f2f2f", borderBottomStyle: "solid" }}>
                    <Typography
                        variant="h5"
                        noWrap
                        sx={{
                            fontWeight: 500,
                            lineHeight: 2,
                            color: '#4f4f4f',
                            textDecoration: 'none'
                        }}>
                        Contrats
                    </Typography>
                </Grid>
                <Box sx={{ ml: 1, mr: 1 }}>
                    {!contracts
                    ? <Typography sx={{m:'auto', pt:10, display:'block', textAlign:'center'}}>Chargement en cours...</Typography>
                    :
                    0 === contracts.length
                    ? <Typography sx={{m:'auto', pt:10, display:'block', textAlign:'center'}}>Vous n'avez aucun contrat.</Typography>
                    :
                    <List
                        id={window.innerHeight > 750 ? "contracts" : "contracts-disable"}
                        sx={{
                            width: '100%',
                            height: "calc(100vh - 308px)",
                            overflowY: 'auto'
                        }} >
                        {contracts.sort((a, b) => b.createdAt - a.createdAt).map((e, idx) => {
                            return <Box key={e.id}>
                                <ContractItem contract={e} even={idx % 2} />
                                {idx !== contracts.length - 1 && <Divider />}
                            </Box>
                        })}
                    </List>}
                </Box>
            </Box>
        </Container>
    );
}

export default DashboardPage;
