import React from 'react';
import { useState } from 'react';
import './RootPage.css';
import DashboardPage from '../DashboardPage/DashboardPage';
import ContractPage from '../ContractPage/ContractPage';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from '../LoginPage/LoginPage';
import AppBar from '../../components/AppBar';

function RootPage() {
    const [, setIsLogin] = useState()

    return (
        <main>
            <AppBar setIsLogin={setIsLogin} />
            <Routes>
                <Route path="/login" element={<LoginPage setIsLogin={setIsLogin} />} />
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/contract/:id" element={<ContractPage />} />
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </main>
    );
}

export default RootPage;
