import React from 'react';
import ReactDOM from 'react-dom/client';
import RootPage from './Pages/RootPage/RootPage';
import { BrowserRouter } from 'react-router-dom';

import './index.css';

const root = ReactDOM.createRoot(
    document.querySelector('body > div') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <RootPage />
        </BrowserRouter>
    </React.StrictMode>
);
