import { Grid, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { Contract } from '../models/contract';
import { useNavigate } from 'react-router-dom';

function StatusTag(props:any) {
    const statusText:{[key:string]:string} = {
        "ongoing": "En cours",
        "compliant": "En attente de payment",
        "notcompliant": "Non conforme",
        "pending": "En attente",
        "enclosed": "Terminé (payé)"
    };

    const statusColor:{[key:string]:string} = {
        "ongoing": "#CC5500",
        "compliant": "#66bb6a",
        "notcompliant": "#f44336",
        "pending": "#29b6f6",
        "enclosed": "#8f8f8f"
    };

    return (<Typography sx={{ color:statusColor[props.state] }}>{statusText[props.state]}</Typography>);
}

function ContractItem(props: any) {
    const even = props.even;
    const contract: Contract = props.contract;
    const id = contract.id || null;
    const ref = contract.ref || null;
    const firstName = contract.trainerFirstName || ""
    const lastName = contract.trainerLastName || ""
    const mission = contract.label ? 50 < contract.label.length ? contract.label.substring(0, 50) + "..." : contract.label : null
    const state = contract.state;
    const navigate = useNavigate()

    if (!id || !ref) {
        console.error("Invalid contract")
        return (<></>);
    }

    let bgColor = even ? "#FFFFFF" : "#F8F8F8";

    return (<>
        <ListItem sx={{ height:"60px", display: { xs: 'none', md: 'flex' }, backgroundColor:bgColor }} onClick={(e) => {
            e.preventDefault()
            navigate(`/contract/${id}`)
        }}>
            <ListItemText primary={ref} secondary={`${firstName} ${lastName} ${mission ? ` • ${mission}` : ""}`} sx={{ flex: 1 }} />
            <ListItemAvatar sx={{ flex: 0.5, textAlign: 'end' }}>
                <StatusTag state={state as string} />
            </ListItemAvatar>
        </ListItem >
        <Grid container sx={{display: { xs: 'flex', md: 'none' }, p: 1, flexDirection: 'column', backgroundColor:bgColor }} onClick={(e) => {
            e.preventDefault()
            navigate(`/contract/${id}`)
        }}>
            <ListItemText primary={ref} secondary={`${firstName} ${lastName} ${mission ? ` • ${mission}` : ""}`} sx={{ flex: 1 }} />
            <Grid sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                <StatusTag state={state as string} />
            </Grid>
        </Grid>
    </>
    );
}

export default ContractItem;
